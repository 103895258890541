<template>
  <b-card-actions
    ref="cardA"
    title="Subscriptions"
    action-collapse
    no-body
  >
    <b-card class="mb-0" no-body>
      <div class="mx-1 mb-2">
        <b-row>
          <b-col cols="2">
            <HeroButtonAction
              type="button"
              variant="primary"
              @click="openAppAuthorization"
            >
              Subscribe Webhook
            </HeroButtonAction>
          </b-col>
          <b-col cols="2" offset="8">
            <HeroButtonAction
              v-if="subscriptions.length > 0"
              type="button"
              variant="danger"
              @click="unsubscribeAll()"
            >
              Unsubscribe All
            </HeroButtonAction>
          </b-col>
        </b-row>
      </div>

      <div class="mx-1">
        <b-row>
          <b-col cols="12">
            <b-table-simple v-if="subscriptions.length > 0" responsive bordered class="text-center" style="font-weight: normal; font-size: 12px; white-space:nowrap;">
              <b-thead>
                <b-tr>
                  <b-th class="smaller">
                    Advertiser Id
                  </b-th>
                  <b-th class="smaller">
                    Advertiser Name
                  </b-th>
                  <b-th class="smaller">
                    TikTok Subscription Id
                  </b-th>
                  <b-th class="smaller">
                    Action
                  </b-th>
                </b-tr>
              </b-thead>

              <b-tbody>
                <template v-for="subscription in subscriptions">
                  <b-tr :key="subscription.id">
                    <b-td style="text-align: left;">
                      {{ subscription.advertiser_id }}
                    </b-td>

                    <b-td style="text-align: left;">
                      {{ subscription.advertiser_name }}
                    </b-td>

                    <b-td style="text-align: left;">
                      {{ subscription.tiktok_subscription_id }}
                    </b-td>

                    <b-td style="text-align: left;">
                      <HeroButtonAction
                        type="button"
                        variant="primary"
                        @click="unsubscribe(subscription.tiktok_subscription_id, subscription.advertiser_name)"
                      >
                        Unsubscribe
                      </HeroButtonAction>
                    </b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-card-actions>
</template>

<script>
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'
import Toastification from '@/services/Toastification'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardActions,
    HeroButtonAction,
  },
  data() {
    return {
      subscriptions: [],
    }
  },
  async mounted() {
    await this.doLoadData()
  },
  methods: {
    async doLoadData() {
      this.$refs.cardA.showLoading = true

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      try {
        const url = 'tiktok/subscriptions'
        const response = await axios.get(url, axiosConfig)

        this.subscriptions = response.data.data

        if (response.data.status === 'success') {
          const toastObject = Toastification.getContentSuccess(`${response.data.message}`)
          this.$toast(toastObject)
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
      }

      this.$refs.cardA.showLoading = false
    },

    async unsubscribe(subscriptionId, advertiserName) {
      const alertResult = await this.$swal({ ...SweetAlert.confirm, text: `Are you sure that you want to unsubscribe ${advertiserName} webhooks.` })

      if (alertResult.value) {
        this.$refs.cardA.showLoading = true

        const token = store.getters['heroAiAuthentications/sanctumToken']
        const axiosConfig = AxiosConfig.getJsonWithToken(token)

        try {
          const payload = {
            subscription_id: subscriptionId,
          }

          const url = 'tiktok/unsubscribe'
          const response = await axios.post(url, payload, axiosConfig)

          this.results = response.data.results

          if (response.data.status === 'success') {
            const toastObject = Toastification.getContentSuccess(`${response.data.message}`)
            this.$toast(toastObject)
            this.doLoadData()
          }
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

          if (error.response.status === 401) {
            await this.$router.push({ name: 'heroai-logout' })
          }
        }

        this.$refs.cardA.showLoading = false
      }
    },

    async unsubscribeAll() {
      const alertResult = await this.$swal({ ...SweetAlert.confirm, text: 'Are you sure that you want to unsubscribe all TikTok webhooks.' })

      if (alertResult.value) {
        this.$refs.cardA.showLoading = true

        const token = store.getters['heroAiAuthentications/sanctumToken']
        const axiosConfig = AxiosConfig.getJsonWithToken(token)

        try {
          const url = 'tiktok/unsubscribe-all'
          const response = await axios.post(url, null, axiosConfig)

          this.subscriptions = []

          if (response.data.status === 'success') {
            const toastObject = Toastification.getContentSuccess(`${response.data.message}`)
            this.$toast(toastObject)
            this.doLoadData()
          }
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

          if (error.response.status === 401) {
            await this.$router.push({ name: 'heroai-logout' })
          }
        }

        this.$refs.cardA.showLoading = false
      }
    },

    openAppAuthorization() {
      window.open(process.env.VUE_APP_TIKTOK_APP_AUTHORIZATION_LINK, '_blank')
    },
  },
}
</script>
